import React from 'react';
import Header from '@/features/Header/Header';
import { generateStyleModifiers } from '@/utils/generateStyleModifiers';
import { HeaderMenuCard } from '@/design-system/molecules/HeaderMenuCard';

const HeaderWrapper = (props) => {
    props.navigationItems.forEach((item) => {
        const menuCardXF = item?.menuCardExperienceFragment?.[':items']?.root?.[':items']?.menu_card;

        if (menuCardXF) {
            const modifiers = generateStyleModifiers(menuCardXF?.appliedCssClassNames);
            const theme = modifiers?.theme;

            item.menuCardComponent = <HeaderMenuCard {...menuCardXF} theme={theme} disableCSR={true} />;
        }
    });

    return <Header {...props} disableCSR={true} />;
};

export default HeaderWrapper;
