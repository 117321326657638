import React from 'react';
import { createRoot } from 'react-dom/client';

import window from '@/utils/window';
import './index.scss';
import App from './App';

window.ahsHeaderFooter = {
    src: document.currentScript.src.replace(/[^/]*$/, ''),
    loadCSS: () => {
        return new Promise((resolve, reject) => {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = `${window.ahsHeaderFooter.src}ahs-header-footer.css?origin=${window?.location?.origin}`;
            link.crossOrigin = 'anonymous';

            // Listen for the load event
            link.onload = () => {
                resolve();
            };

            // Handle errors (optional)
            link.onerror = () => {
                console.error(`Failed to load CSS file ${link.href}.`);
                reject();
            };

            document.head.appendChild(link);
        });
    },
    /**
     * Initialize the header and footer components
     * @param {string} headerLocation - The location of the header container
     * @param {string} footerLocation - The location of the footer container
     * @param {string} headerContentUrl - The URL of the header model.json
     * @param {string} footerContentUrl - The URL of the footer model.json
     */
    init: ({ headerLocation, headerContentUrl, footerLocation, footerContentUrl, options }) => {
        const fetchAndRender = async () => {
            // Check if the header and footer URLs are provided
            if (!headerContentUrl && !footerContentUrl) {
                console.error('No valid URLs provided for header/footer');
                return;
            }

            // Check if the header and footer locations are provided
            if (!headerLocation && !footerLocation) {
                console.error('No valid locations provided for header/footer');
                return;
            }
            try {
                // Get the model data from the provided URLs
                const headerResponse = headerContentUrl ? await fetch(headerContentUrl) : null;
                const footerResponse = footerContentUrl ? await fetch(footerContentUrl) : null;

                // Parse the responses as JSON and extract props
                const headerModel = headerResponse ? await headerResponse.json() : null;
                const footerModel = footerResponse ? await footerResponse.json() : null;

                const headerProps = headerModel
                    ? Object.values(headerModel[':items']?.['root']?.[':items'])?.find(
                          (item) => item[':type'] === 'ahs/components/navigation-header',
                      )
                    : null;
                const footerProps = footerModel
                    ? Object.values(footerModel[':items']?.['root']?.[':items'])?.find(
                          (item) => item[':type'] === 'ahs/components/footer',
                      )
                    : null;

                // Set options
                if (options) {
                    if (options.onLanguageChange) {
                        headerProps.onLanguageChange = options.onLanguageChange;
                    }

                    if (options.hideLanguageSelector) {
                        headerProps.hideLanguageSelector = options.hideLanguageSelector;
                    }

                    if (options.languageDomains) {
                        const languageCtas = headerProps.utilityNavigationItems[1]?.children || [];
                        const languageKeys = new Set(Object.keys(options.languageDomains));

                        for (const cta of languageCtas) {
                            if (languageKeys.has(cta.label)) {
                                cta.href = options.languageDomains[cta.label];
                            }
                        }

                        headerProps.utilityNavigationItems[1].children = languageCtas;
                    }
                }

                // Get the header and footer containers
                const headerContainer = document.getElementById(headerLocation);
                const footerContainer = document.getElementById(footerLocation);

                // If a Header location was provided, check that the header props are valid
                if (headerLocation && !headerProps) {
                    console.error('No valid props found for header');
                    return;
                }

                // If a Footer location was provided, check that the footer props are valid
                if (footerLocation && !footerProps) {
                    console.error('No valid props found for footer');
                    return;
                }

                // Check if the header and footer containers are valid
                if (!headerContainer && !footerContainer) {
                    console.error(
                        'No valid containers found for specified header/footer locations',
                    );
                    return;
                }

                // Create a root for the header-footer app
                const root = createRoot(headerContainer || footerContainer);

                // Render the App component with the fetched data
                root.render(
                    <App
                        headerProps={headerProps}
                        footerProps={footerProps}
                        headerContainer={headerContainer}
                        footerContainer={footerContainer}
                    />,
                );
            } catch (error) {
                console.error('Failed to fetch and render:', error);
            }
        };

        window.ahsHeaderFooter.loadCSS().then(() => {
            // Fetch and render the header and/or component
            fetchAndRender();
        });
    },
};
